var Vue = require('vue');
var axios = require('axios');

const serialize = obj => {
   let str = [];
   for (let p in obj)
     if (obj.hasOwnProperty(p)) {
       str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
     }
   return str.join("&");
 };

const Newsletter = new Vue({

	el: '#newsletter',
	data: {
		email: '',
		submitted: false,
		error: false,
	},

	methods: {

		saveData(){

			var postData = {
				action : 'newsletterInsert',
				email : this.email,
				categories : this.$refs.categories.value
			};

			var config = {
				headers: {
   					'Content-Type': 'application/x-www-form-urlencoded'
 				}
			}

			axios.post(AJAX_URL, serialize(postData), config)
			.then(function (response) {
				Newsletter.submitted = true;
			})
			.catch(function (error) {
				Newsletter.error = true;
			});

		},

	},
});