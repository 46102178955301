var Vue = require('vue');
var axios = require('axios');
var questions = document.getElementById('questions');
var questionJson = JSON.parse(questions.text);

const serialize = obj => {
   let str = [];
   for (let p in obj)
     if (obj.hasOwnProperty(p)) {
       str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
     }
   return str.join("&");
 };

const Quiz = new Vue({

	el: '#quiz',
	data: {
		questions: questionJson,
		current: 0,
		loading: false,
		progress: 0,
		category: 'none',
		categorySlug: 'none',
		age: 3,
		alternatives: [
			{
				'index' : 0,
				'title' : 'Kan inte alls'
			},
			{
				'index' : 1,
				'title': 'Kan nästan',
			},
			{
				'index' : 3,
				'title': 'Kan hyggligt',
			},
			{
				'index' : 5,
				'title': 'Kan bra',
			}
		],
		answers: [

		]
	},

	methods: {

		next: function () {

			if( this.current <= (this.questions[this.age].length)  )
			{
				this.current = this.current + 1;
				this.updateQuiz();
			}
			else
			{
				this.current = -1;
				this.loading = true;

				this.saveData();
			}
		},

		prev: function () {
			this.current = this.current - 1;
			this.updateQuiz();
		},

		answer: function(answerIndex, labelIndex, questionMeta ){
			console.log(questionMeta);
			// Store answe
			Vue.set(
				Quiz.answers,
				answerIndex,
				{
					'post_id' :  questionMeta['id'],
					'answer': labelIndex,
				}

			);

			this.next();
			this.updateQuiz();
		},

		setYear: function(year) {
			this.age = year;

			this.next();
			this.updateQuiz();
		},

		snakeUpdate(){

			if( this.age && this.current >= 2 ){

				this.categorySlug = this.questions[this.age][this.current - 2]['categorySlug'];
			}
		},

		progressUpdate: function(){

			if( this.age ){

				this.progress = (((this.current - 1) / this.questions[this.age].length) * 100) + '%';

			}
		},

		updateQuiz: function(){
			this.snakeUpdate();
			this.progressUpdate();
		},

		saveData(){

			var postData = {
				action : 'quizInsert',
				age: this.age,
				answers : JSON.stringify(this.answers),
			};

			var config = {

				headers: {
   					'Content-Type': 'application/x-www-form-urlencoded'
 				}

			}


			axios.post(AJAX_URL, serialize(postData), config)
			.then(function (response) {
				var slug = response.data.slug;

				setTimeout(function(){
					window.location.href = "/resultat?slug=" + slug;
				}, 1000);

			})
			.catch(function (error) {
				console.log(error);
			});

		}

	},

	mounted() {
		/*
		if( localStorage.current ) 
		{
			this.current = localStorage.current;
			this.age = localStorage.age;
			this.answers = localStorage.answers;
		}
		*/
	}
})