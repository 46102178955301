import Swiper from 'swiper';
import lottie from 'lottie-web';

var excerciseSwiper = new Swiper('.swiper-container', {
    direction: 'horizontal',
    slidesPerView : 2,
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

});

// Screen width
var windowWidth = window.innerWidth;
var mobile = 499;
window.addEventListener('resize', function(){
	windowWidth = window.innerWidth;
});

// Dropdown
if( document.getElementById('dropdown') ){
	var Vue = require('vue');
	const dropdown = new Vue({

		el: '#dropdown',
		data: {
			vissible: false
		},

		methods: {

			show: function (bool) {
				this.vissible = bool;
			},

		}

	});
}

// Newsletter
if( document.getElementById('newsletter') )
{
	require('./newsletter.js');
}

// Quiz
if( document.getElementById('quiz') )
{
	require('./quiz.js');
}

if( document.getElementById('results'))
{
	require('./results.js');
}

// Quickfix for now.
var animations = [
	'balans',
	'koordination',
	'ga-springa',
	'hoppa-landa',
	'objektskontroll'
];

animations.forEach(function(item, key){

	if( document.getElementById('animate-' + item) )
	{

		animations[key] = lottie.loadAnimation({
			container: document.getElementById('animate-' + item),
			renderer: 'svg',
			loop: false,
			autoplay: false,
			path: THEME_DIR + '/img/animate-' +  item + '.json'
		});

		if ( windowWidth > mobile ) {
			
			document.getElementById('animate-' + item).onmouseover = function(){
				animations[key].play();
				animations[key].loop = true;
			}

			document.getElementById('animate-' + item).onmouseout = function(){
				animations[key].loop = false;
			}

		} else {
		
			animations[key].play();
			animations[key].loop = true;

		}
	}

	if( document.getElementById('loop-animate-' + item) )
	{

		animations[key] = lottie.loadAnimation({
			container: document.getElementById('loop-animate-' + item),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			path: THEME_DIR + '/img/animate-narrow-' +  item + '.json'
		});
	}


});

var playButtons = document.getElementsByClassName('js-play');
if( playButtons ){

	if ( windowWidth > mobile ) {

		var activatePlay = function() {
			var playHolder = this.parentElement;
		    var iframePlayer = playHolder.getElementsByClassName('js-player');
			var itemHolder = playHolder.parentElement;
		    var videoId = playHolder.dataset.src;

		    // Close all and stop playing.
		    closeAll();

		    itemHolder.classList.add('active');
		    iframePlayer[0].setAttribute('src', videoId);

		    setTimeout(function(){
		    	itemHolder.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}, 700)
		};


		Array.from(playButtons).forEach(function(item) {
		  	item.addEventListener('click', activatePlay);
		});

		var closeButtons = document.getElementsByClassName('js-close');
		Array.from(closeButtons).forEach(function(item) {
		  item.addEventListener('click', closeAll);
		});

		var allItems = document.getElementsByClassName('exercise-item');
		function closeAll(){
			Array.from(allItems).forEach(function(item){
				if( item.classList.contains('active') ){
					var iframe = item.getElementsByClassName('js-player');
					iframe[0].setAttribute('src', '');
					item.classList.remove('active');
				}
			});
		}

	} else {

		Array.from(playButtons).forEach(function(item) {

			var playHolder = item.parentElement;
		    var iframePlayer = playHolder.getElementsByClassName('js-player');
			var itemHolder = playHolder.parentElement;
		    var videoId = playHolder.dataset.src;

		    itemHolder.classList.add('active');
		    iframePlayer[0].setAttribute('src', videoId);

		});

	}
}
	
var menuOpen = false,
	mainHeader = document.getElementById('main-header'),
	mobileNav = document.getElementById('mobile-nav');

mobileNav.addEventListener('click', function(){

	if ( !menuOpen ) {
		mainHeader.classList.add('open');
		menuOpen = true;
	} else {
		mainHeader.classList.remove('open');
		menuOpen = false;
	}
});